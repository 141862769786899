import React, { useEffect, useState, useCallback } from 'react'
import { Container, Row, Col, Button, Modal, Alert } from "react-bootstrap";
import image from "./images/title.png";
import Cursor from "./components/Cursor";
import * as API from './utils/api';
import { FaImage, FaSearch } from 'react-icons/fa';
import { BsHandIndexThumbFill } from 'react-icons/bs';
import { GiArrowCursor } from 'react-icons/gi';
import axios from 'axios';
import { TextField, FormHelperText, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import swal from 'sweetalert';



function App() {

  const [loading, setLoading] = useState(false);
  const [dialogueAdd, setDialogueAdd] = useState(false);
  const [dialogueAddCollection, setDialogueAddCollection] = useState(false);


  // Cursor, Categories and Collections
  const [collections, setCollections] = useState([]);
  const [cursors, setCursors] = useState([]);
  const [categories, setCategories] = useState([]);

  // Add Dialog
  const [addName, setAddName] = useState("");
  const [addDescription, setAddDescription] = useState("");
  const [addCategory, setAddCategory] = useState("-1");
  const [addCollection, setAddCollection] = useState("-1");
  const [addImageLeft, setAddImageLeft] = useState(null);
  const [addImageRight, setAddImageRight] = useState(null);

  // Add Collection Dialog
  const [addCollectionName, setAddCollectionName] = useState("");
  const [addCollectionDescription, setAddCollectionDescription] = useState("");
  const [addCollectionImage, setAddCollectionImage] = useState(null);

  // Search Parameters
  const [search, setSearch] = useState("");
  const [searchedCursors, setSearchedCursors] = useState([]);
  const [searchCategory, setSearchCategory] = useState("-1");
  const [searchCollection, setSearchCollection] = useState("-1");



  useEffect(() => {
    async function run() {
      try {
        setLoading(true)
        const cursors = await API.GetAPI('/api/customcursor'); //first 20
        const collections = await API.GetAPI('/api/customcursor/collection');
        const categories = await API.GetAPI('/api/customcursor/categories');


        if (!cursors.result && !cursors.error) setCursors(cursors);
        if (!collections.result && !collections.error) setCollections(collections);
        if (!categories.result && !categories.error) setCategories(categories)

      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
    run();
  }, []);

  const onSearch = useCallback(async () => {
    try {
      setLoading(true);
      let query = '';
      if (search) query += `search=${encodeURIComponent(search)}`;
      if (searchCategory) query += (query ? `category=${searchCategory}` : `&category=${searchCategory}`);
      if (searchCollection) query += (query ? `collection=${searchCollection}` : `&collection=${searchCollection}`);
      const cursors = await API.GetAPI(`/api/customcursor?${query}`); //first 20
      setSearchedCursors(cursors);
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  }, [search, searchCategory, searchCollection]);

  const onAddCursor = async () => {
    try {
      setLoading(true)
      const cursor = {
        name: addName,
        description: addDescription,
        category: addCategory !== "-1" ? addCategory : null,
        collections: addCollection !== "-1" ? addCollection : null
      }

      if (cursor.name.replace(/\s/gmi, '') === '') return swal('Adding Cursor', 'Please enter name', 'warning');
      if (!cursor.category) return swal('Adding Cursor', 'Please sekect a category', 'warning');
      if (!addImageLeft && !addImageRight) return swal('Adding Cursor', 'Please uploaded at least on cursor', 'warning');

      // Add the cursor
      const res = await API.PostAPI('/api/customcursor', cursor);
      if (res.result) {
        if (addImageLeft) {
          const data = new FormData();
          data.append('image', addImageLeft);
          const domain = process.env.REACT_APP_BACKEND;
          const token = await API.getAccessToken();
          const result = await axios({
            url: `${domain}/api/customcursor/${res.id}/left`,
            method: "PATCH",
            headers: { 'Authorization': 'Bearer ' + token, },
            data: data
          });
          console.log(result.data)
        }

        if (addImageRight) {
          const data = new FormData();
          data.append('image', addImageRight);
          const domain = process.env.REACT_APP_BACKEND;
          const token = await API.getAccessToken();
          const result = await axios({
            url: `${domain}/api/customcursor/${res.id}/right`,
            method: "PATCH",
            headers: { 'Authorization': 'Bearer ' + token, },
            data: data
          });

          console.log(result.data)
        }
      }

      swal('Adding Cursor', res.message, 'success');
      setAddImageLeft(null);
      setAddImageRight(null);
      document.getElementById('file-left').value = null;
      document.getElementById('file-right').value = null;
    } catch (e) {
      console.log(e.message);
      swal('Adding Cursor', e.message, 'error');
    } finally {
      setLoading(false);
      
    }
  }


  const onUpload = async (e, direction) => {
    try {

      const file = e.target.files[0];

      if (!file) {
        document.getElementById('file-left').value = null;
        document.getElementById('file-right').files = null;
        document.getElementById('file-collection').files = null;
        return swal('Custom Cursor', 'Invalid file', 'error');
      }

      if (direction === 'left') setAddImageLeft(file)
      else if (direction === 'right') setAddImageRight(file)
      else setAddCollectionImage(file)


    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }


  const onAddCollection = async () => {
    try {
      setLoading(true)
      const collection = {
        name: addCollectionName,
        description: addCollectionDescription,
      }

      if (collection.name.replace(/\s/gmi, '') === '') return swal('Adding Collection', 'Please enter name', 'warning');
      if (!addCollectionImage) return swal('Adding Collection', 'Please uploaded at least on cursor', 'warning');

      const data = new FormData();
      data.append('name', collection.name);
      data.append('description', collection.description);
      data.append('image', addCollectionImage);
      const domain = process.env.REACT_APP_BACKEND;
      const token = await API.getAccessToken();
      const result = await axios({
        url: `${domain}/api/customcursor/collection`,
        method: "POST",
        headers: { 'Authorization': 'Bearer ' + token, },
        data: data
      });

      const res = result.data;

      swal('Adding Collection', res.message, 'success');
      setAddCollectionImage(null)
      document.getElementById('file-collection').value = null;
    } catch (e) {
      console.log(e.message);
      swal('Adding Collection', e.message, 'error');
    } finally {
      setLoading(false);
      
    }
  }


  return (
    <Container className="centralise" fluid>

      <img src={image} alt="title" style={{ width: "100%" }} />
      <br /><br />

      <div style={{ display: "flex", float: "right" }}>
        <Button variant="primary" className="dropShadow round hover" onClick={() => setDialogueAdd(true)}>Add Cursor</Button>
        <Button variant="primary" className="dropShadow round hover" onClick={() => setDialogueAddCollection(true)}>Add Collection</Button>
      </div>

      <div style={{ padding: 20, maxWidth: 1200, margin: "0 auto" }}>
        <TextField size="small" label={<div><FaSearch /> Search</div>} placeholder="Search..." type="search" value={search} onChange={e => setSearch(e.target.value)} style={{ width: "80%" }} />
        <Button variant="primary" onClick={onSearch}><FaSearch /></Button>
        <Row xs={1} sm={2}>
          <Col>
            <FormControl size="small" sx={{ m: 1, minWidth: "90%" }}>
              <InputLabel id="category">Select a Category</InputLabel>
              <Select size="small" name="category" labelId="msg" id="msg" value={searchCategory} onChange={e => setSearchCategory(e.target.value)} label="Select a Category">
                <MenuItem disabled value="-1">
                  <em>Select a Category</em>
                </MenuItem>
                {
                  categories.sort((a, b) => a.name.localeCompare(b.name)).map((cat, i) =>
                    <MenuItem key={'cat' + i} value={cat.name}>{cat.name}</MenuItem>
                  )
                }

              </Select>
              <FormHelperText>Select a Category</FormHelperText>
            </FormControl>
          </Col>
          <Col>
            <FormControl size="small" sx={{ m: 1, minWidth: "90%" }}>
              <InputLabel id="category">Select a Collection</InputLabel>
              <Select size="small" name="category" labelId="msg" id="msg" value={searchCollection} onChange={e => setSearchCollection(e.target.value)} label="Select a Collection">
                <MenuItem disabled value="-1">
                  <em>Select a Collection</em>
                </MenuItem>
                {
                  collections.sort((a, b) => a.name.localeCompare(b.name)).map(collection =>
                    <MenuItem key={collection._id} value={collection.name}>{collection.name}</MenuItem>
                  )
                }
              </Select>
              <FormHelperText>Select a Collection</FormHelperText>
            </FormControl>
          </Col>
        </Row>
        <br />
        {
          searchedCursors.length ?
            <Row xs={1} sm={2} md={3} lg={4}>

            </Row>
            :
            <Row xs={1} sm={2} md={3} lg={4}>
              {
                cursors.map((cursor, index) =>
                  <Cursor
                    key={index}
                    cursor={cursor}
                  />
                )
              }
            </Row>
        }

      </div>


      <Modal size="lg" show={dialogueAdd} onHide={() => setDialogueAdd(false)} aria-labelledby="modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">Add Cursor</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TextField size="small" label="Name" placeholder="Name" name="name" type="name" value={addName} onChange={e => setAddName(e.target.value)} style={{ width: "100%" }} />
          <br /> <br />
          <TextField size="small" multiline label="Description" name="description" placeholder="Brief Description of the cursor" type="text" value={addDescription} onChange={e => setAddDescription(e.target.value)} style={{ width: "100%" }} />
          <br /> <br />
          <FormControl size="small" sx={{ m: 1, minWidth: "90%" }}>
            <InputLabel id="category">Select a Category</InputLabel>
            <Select size="small" name="category" labelId="msg" id="msg" value={addCategory} onChange={e => setAddCategory(e.target.value)} label="Select a Category">
              <MenuItem disabled value="-1">
                <em>Select a Category</em>
              </MenuItem>
              {
                categories.sort((a, b) => a.name.localeCompare(b.name)).map((cat, i) =>
                  <MenuItem key={'cat' + i} value={cat.name}>{cat.name}</MenuItem>
                )
              }

            </Select>
            <FormHelperText>Select a Category</FormHelperText>
          </FormControl>

          <FormControl size="small" sx={{ m: 1, minWidth: "90%" }}>
            <InputLabel id="category">Select a Collection</InputLabel>
            <Select size="small" name="category" labelId="msg" id="msg" value={addCollection} onChange={e => setAddCollection(e.target.value)} label="Select a Collection">
              <MenuItem disabled value="-1">
                <em>Select a Collection</em>
              </MenuItem>
              {
                collections.sort((a, b) => a.name.localeCompare(b.name)).map(collection =>
                  <MenuItem key={collection._id} value={collection.name}>{collection.name}</MenuItem>
                )
              }
            </Select>
            <FormHelperText>Select a Collection</FormHelperText>
          </FormControl>

          <input style={{ position: "absolute", display: "none" }} onChange={(e) => onUpload(e, 'left')} id="file-left" type="file" accept="image/*" />
          <input style={{ position: "absolute", display: "none" }} onChange={(e) => onUpload(e, 'right')} id="file-right" type="file" accept="image/*" />
          <Row xs={1} sm={2}>
            <Col>
              <Alert variant="light" className="dropShadow round">
                {addImageLeft ? <img src={URL.createObjectURL(addImageLeft)} width={100} alt="cursor" /> : <GiArrowCursor size={100} />}
                <Button disabled={loading} style={{ marginTop: 10 }} variant="primary" className="round hover dropShadow" onClick={() => document.getElementById('file-left').click()}>
                  <GiArrowCursor />{" "} Upload Pointer
                </Button>
              </Alert>
            </Col>
            <Col>
              <Alert variant="light" className="dropShadow round">
                {addImageRight ? <img src={URL.createObjectURL(addImageRight)} width={100} alt="cursor" /> : <BsHandIndexThumbFill size={100} />}
                <Button disabled={loading} style={{ marginTop: 10 }} variant="primary" className="round hover dropShadow" onClick={() => document.getElementById('file-right').click()}>
                  <BsHandIndexThumbFill />{" "} Upload Hand
                </Button>
              </Alert>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDialogueAdd(false)} >
            CANCEL
          </Button>
          <Button disabled={loading} variant="primary" onClick={onAddCursor}>
            ADD CURSOR
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal size="lg" show={dialogueAddCollection} onHide={() => setDialogueAddCollection(false)} aria-labelledby="modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">Add Collection</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TextField size="small" label="Name" placeholder="Name" name="name" type="name" value={addCollectionName} onChange={e => setAddCollectionName(e.target.value)} style={{ width: "100%" }} />
          <br /> <br />
          <TextField size="small" multiline label="Description" name="description" placeholder="Brief Description of the cursor" type="text" value={addCollectionDescription} onChange={e => setAddCollectionDescription(e.target.value)} style={{ width: "100%" }} />
          <br /> <br />

          <input style={{ position: "absolute", display: "none" }} onChange={onUpload} id="file-collection" type="file" accept="image/*" />
          <Alert variant="light" className="dropShadow round">
            {addCollectionImage ? <img src={URL.createObjectURL(addCollectionImage)} width={100} alt="cursor" /> : <FaImage size={100} />}
            <Button disabled={loading} style={{ marginTop: 10 }} variant="primary" className="round hover dropShadow" onClick={() => document.getElementById('file-collection').click()}>
              <FaImage />{" "} Collection Image
            </Button>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDialogueAddCollection(false)} >
            CANCEL
          </Button>
          <Button disabled={loading} variant="primary" onClick={onAddCollection}>
            ADD COLLECTION
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default App;
