import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import * as API from '../utils/api';

function Cursor({ cursor }) {

  const [loading, setLoading] = useState(false);

  const onApproval = () => { };

  const onDecline = async () => {

    const result = await swal({
      title: `Remove ${cursor.name}`,
      text: `Do you want to remove ${cursor.name}?`,
      icon: "info",
      buttons: ['CANCEL', 'REMOVE']
    })
    if (!result) return;


    try {
      setLoading(true);


      const res = await API.DeleteAPI(`/api/customcursor/${cursor._id}`);
      swal(`Remove ${cursor.name}`, res.message, res.result ? 'success' : 'warning');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

  };

  return (
    <Card className="dropShadow">
      <br />
      <Row xs={1} sm={2}>
        <Col>
          <Card.Img variant="top" src={cursor.left} />
        </Col>
        <Col>
          <Card.Img variant="top" src={cursor.right} />
        </Col>
      </Row>
      <Card.Body>
        <Card.Title><strong>{cursor.name}</strong></Card.Title>
        <Card.Title style={{ color: "grey" }}><h6><i>{cursor.category}</i> - {cursor.collection}</h6></Card.Title>
        <Button disabled={loading} style={{ marginRight: 10 }} variant="primary" className="round hover dropShadow" onClick={onApproval}>{loading ? "Loading..." : "EDIT"}</Button>
        <Button disabled={loading} variant="dark" className="hover dropShadow round" onClick={onDecline}>{loading ? "Loading..." : "DELETE"}</Button>
      </Card.Body>
    </Card>
  )
}

export default Cursor;
